import React, { Component } from "react";
import HomeServiceOne from "./HomeServiceOne";

class HomeSlider extends Component {
  render() {
    return (
        <div className="slider-activation">
            {/* Start Single Slide */}
            <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--1" >
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner">
                                <h1 className="title theme-gradient">One Hundred <br /> Black Devs. </h1>
                                <div class="section-title mt--15">
                                    <p class="description">A boutique workforce development company that specializes in advancing the careers of Blacks in technology.  Our “4Ps” strategy - Preparation, Position, Practice and Placement - focuses uniquely on the needs of companies and individuals to more effectively close the hiring gap in the tech industry.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Service Area */}
                    {/* <div className="service-wrapper service-white">
                        <HomeServiceOne />
                    </div> */}
                    {/* End Service Area */}
                </div>
            </div> 
            {/* End Single Slide */}
        </div>
    );
  }
}
export default HomeSlider;