import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'Software Development',
        description: 'Customer software, web and app development for startups, small and medium-sized businesses.',
        // url:'/software-development'
        url:'/#'
    },
    {
        icon: '03',
        title: 'Career Growth Platform',
        description: 'Development and growth platform designed to generate a network of skilled technology professionals.',
        url: '/career-platform'
    },
    {
        icon: '02',
        title: 'Talent Placement',
        description: 'Fast-tracked discovery and placement of trained technology professionals.',
        // url: 'talent-placement'
        url:'/#'
    },
]

class HomeServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row section-title">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/>
                                </div>
                                <div className="content">
                                <a href={`${val.url}`}><h4 className="title">{val.title}</h4></a>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default HomeServiceOne;