import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiMail , FiMapPin } from "react-icons/fi";
// import ContactForm from "../elements/ContactForm";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <div className="active-dark">
                <PageHelmet pageTitle='Contact' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" sublink />

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area pt--120 pb--190 "  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Lets Chat!</h2>
                                    <p>We would love to hear from you. Send us an email with any feedback, questions or inquiries you may have.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--1">
                    <div className="container">
                       
                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="row service-one-wrapper">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12" >
                                        <a href="/service-details">
                                            <div className="service service__style--2">
                                                <div className="icon">
                                                    <FiMail />
                                                </div>
                                                <div className="content">
                                                    <h3 className="title">Email Address</h3>
                                                    <p>info@100blackdevs.com</p>
                                                    <p> </p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12" >
                                    <div className="service service__style--2">
                                        <div className="icon">
                                            <FiMapPin />
                                        </div>
                                        <div className="content">
                                            <h3 className="title">Mailing Address</h3>
                                            <p>PO BOX 465987<br /> Lawrenceville, GA 30042</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                {/* <div className="rn-contact-page ptb--120 bg_color--5">
                    <ContactForm />
                </div> */}
                {/* End Contact Page Area  */}

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--1 ptb--120">
                    {/* <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div> */}
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
                
            </div>
        )
    }
}
export default Contact