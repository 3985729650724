import React, { PureComponent } from "react";
import { FiLayers , FiUsers , FiMonitor, FiCompass } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiMonitor />,
        title: 'Software Development',
        description: 'Custom web and software systems development for start-ups and small businesses using the popular frameworks and cloud platforms.',
        // url: '/software-development'
        url:'/#services'
    },
    {
        icon: <FiLayers />,
        title: 'Diversity, Equity & Inclusion',
        description: 'DE&I strategy consulting to create an environment, culture and branding thats attract and helps to retain diverse talent.',
        // url: '/diversity-inclusion'
        url:'/#services'
    },
    {
        icon: <FiCompass />,
        title: 'Career Development',
        description: 'Membership portal to position individuals for their next career opportunity.  Training and coaching to respectively upskill and properly navigate the tech industry.',
        url: '/career-platform'
    },
    {
        icon: <FiUsers />,
        title: 'Talent Placement',
        description: 'Work closely work companies to understand their unique talent needs and match them with candidates that have the necessary skills and career aspirations.',
        url:'/talent-placement'
    }
]

class HomeServiceTwo extends PureComponent {
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">Services</h2>
                            <p>
                                We provide services to help close the diversity gap in tech.
                            </p>
                            {/* <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/service"><span className="text">Request Custom Service</span></a>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href={`${val.url}`}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default HomeServiceTwo;
