import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <a href="https://cirrusmd.com" target="blank">
                            <img src="/assets/images/brand/cirrus-md-web-logo-grey.png" alt="CirrusMD"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.cloudera.com" target="blank">
                            <img src="/assets/images/brand/cloudera-web-logo-grey.png" alt="Cloudera"/>
                        </a>
                    </li>
                    {/* <li>
                        <a href="https://www.cockroachlabs.com" target="blank">
                            <img src="/assets/images/brand/cockroachdb-web-logo-grey.png" alt="Cockroach Labs"/>
                        </a>
                    </li> */}
                    <li>
                        <a href="https://newgeorgiaproject.org" target="blank">
                            <img src="/assets/images/brand/ngp-web-logo-grey.png" alt="New Georgia Project"/>
                        </a>
                    </li>
                    <li>
                        <a href="https://7factor.io" target="blank">
                            <img src="/assets/images/brand/7factor-web-logo-grey.png" alt="7Factor Software"/>
                        </a>
                    </li>
                    {/* <li>
                        <a href="http://steamthestreets.org/" target="blank">
                            <img src="/assets/images/brand/sts-web-logo-grey.png" alt="Steam the Streets"/>
                        </a>
                    </li> */}
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;