import React, { Component } from "react";

class About extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-girl-2.png" alt="About 100 Black Devs"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">About</h2>
                                        <p className="description">
                                            100 Black Devs provides software development, talent placement, and career growth services while employing and training 
                                            individuals from the Black tech community.  The Black community is a rich, un-tapped and even ignored source of tech talent.  
                                            We actively educate, coach and mentor individuals as well as provide employment opportunities on our development engagements.  
                                            As individuals grow or seek long-term employment, we assist in placement with partner companies to help fill their tech talent needs.  
                                            Simply put - we train people and help put people to work.    
                                        </p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who we are</h3>
                                                <p>We’re a collection of individuals passionate about software, systems development and technology.  
                                                    We also care deeply about the Black community and increasing its presence in the tech industry.  
                                                    Through our platform we proudly and boldly get to engage in both every day.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;