import React, { PureComponent } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class TalentPlacement extends PureComponent {
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <div className="active-dark alternate">
                    {/* Start Pagehelmet  */}
                    <PageHelmet pageTitle='Talent Placement' />
                    {/* End Pagehelmet  */}

                    <Header headertransparent="header--transparent" colorblack="color--black" />

                    {/* Start Breadcrumb Area */}
                    <div className="rn-page-title-area pt--120 pb--100 bg_image bg_image--24" data-black-overlay="7">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="rn-page-title text-center pt--100">
                                        <h2 className="title theme-gradient">TALENT PLACEMENT</h2>
                                        <p>Help Companies Acquire Valuable Talent While Increasing Diversity</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Breadcrump Area */}

                    {/* Start Page Wrapper */}
                    <div className="rn-service-details ptb--120 bg_color--1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="service-details-inner">
                                        <div className="inner">
                                            {/* Start Single Area */}
                                            <div className="row service-details-content pb--80 align-items-center">
                                                <div className="col-lg-6 col-12">
                                                    <div className="thumb">
                                                        <img className="w-75" src="/assets/images/talent-placement-1.png" alt="100 Black Devs - Talent Placement"/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <div className="details mt_md--30 mt_sm--30">
                                                        <h4 className="title">It's the Experience for Us</h4>
                                                        <p>We are community leaders, software engineers and HR professionals with over 25 years of software development, HR management and technical recruiting.  With our combined experiences we possess unique and powerful insights on the needs of both corporations and technology professionals.</p>
                                                        <p>Knowing intimately the day to day tasks and expectations in a corporate technology setting allows us to prepare candidates and coach them on things they won't learn in a coding class.</p>
                                                        <h4 className="title">Placement is Key</h4>
                                                        <p>Our goal is to help companies find talent and technologists land the job.  That's it.  We're not satisfied until there is a hire so we spend extra time learning the details and true requirements of a position to ensure the candidates we submit can do the job.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Single Area */}

                                            {/* Start Single Area */}
                                            <div className="row service-details-content align-items-center">
                                                <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                    <div className="details mt_md--30 mt_sm--30">
                                                        <h4 className="title">The Process</h4>
                                                        <p>Once we are engaged with your company we execute the following 4-step process:</p>
                                                        <ul className="liststyle mt--10">
                                                            <li>Analyze the position(s) and working environment</li>
                                                            <li>Identify potential candidates</li>
                                                            <li>Identify and address any knowledge or experience gaps</li>
                                                            <li>Submit candidates promised to bring value to the position</li>
                                                        </ul>
                                                        <h4 className="title">Need Help Identifying Talent?</h4>
                                                        <p>Send an email to info@100blackdevs.com or click below</p>
                                                        <p> <a className="rn-button-style--2" href="mailto:info@100blackdevs.com" rel="noopener noreferrer"><span>Contact Us</span></a></p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                    <div className="thumb position-relative">
                                                        <img className="w-75" src="/assets/images/talent-placement-2.png" alt="100 Black Devs - Talent Placement"/>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Single Area */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Page Wrapper */}
                    
                    {/* Start Back To Top */}
                    <div className="backto-top">
                        <ScrollToTop showUnder={160}>
                            <FiChevronUp />
                        </ScrollToTop>
                    </div>
                    {/* End Back To Top */}
                    
                    <Footer />
                </div>
            </React.Fragment>
        )
    }
}
export default TalentPlacement;