import React, { PureComponent } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class DiversityInclusion extends PureComponent {
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <div className="active-dark alternate">
                    {/* Start Pagehelmet  */}
                    <PageHelmet pageTitle='Talent Placement' />
                    {/* End Pagehelmet  */}

                    <Header headertransparent="header--transparent" colorblack="color--black" />

                    {/* Start Breadcrumb Area */}
                    <div className="rn-page-title-area pt--120 pb--100 bg_image bg_image--26" data-black-overlay="7">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="rn-page-title text-center pt--100">
                                        <h2 className="title theme-gradient">DIVERSITY, EQUITY &amp; INCLUSION</h2>
                                        <p>Fresh From The Press Discription</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Breadcrump Area */}

                    {/* Start Page Wrapper */}
                    <div className="rn-service-details ptb--120 bg_color--1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="service-details-inner">
                                        <div className="inner">
                                            {/* Start Single Area */}
                                            <div className="row service-details-content pb--80 align-items-center">
                                                <div className="col-lg-6 col-12">
                                                    <div className="thumb">
                                                        <img className="w-75" src="/assets/images/service-01.png" alt="Service Images"/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Our Process</h4>
                                                        <p>To help you create a more equitable and inclusive workforce, we will consult with leadership and employees to learn and understand the current company dynamics.  We will assess the company’s hiring practices, internal culture and external/digital marketing.  </p>
                                                        <p>The deliverable as a result of this consultation will be an inclusive hiring strategy providing guidelines on achieving your stated goals for diversity. The strategy will also include a snapshot of your current workplace demographics, your defined goals and the benefits of why you should invest time and resources on this initiative.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Single Area */}

                                            {/* Start Single Area */}
                                            <div className="row service-details-content align-items-center">
                                                <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                    <div className="details mt_md--30 mt_sm--30">
                                                        <h4 className="title">What We'll Do</h4>
                                                        <ul className="liststyle">
                                                            <li>Assess and analyze internal processes and data</li>
                                                            <li>Assess and analyze marketing and branding</li>
                                                            <li>Determine goals and objectives</li>
                                                            <li>Summarize data and determine strategy for achieving D&amp;I goals</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                    <div className="thumb position-relative">
                                                        <img className="w-75" src="/assets/images/service-02.png" alt="Service Images"/>
                                                        {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                                        <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Single Area */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Page Wrapper */}
                    
                    {/* Start Back To Top */}
                    <div className="backto-top">
                        <ScrollToTop showUnder={160}>
                            <FiChevronUp />
                        </ScrollToTop>
                    </div>
                    {/* End Back To Top */}
                    
                    <Footer />
                </div>
            </React.Fragment>
        )
    }
}
export default DiversityInclusion;