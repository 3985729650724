import React, { PureComponent } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class CareerPlatform extends PureComponent {
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <div className="active-dark alternate">
                    {/* Start Pagehelmet  */}
                    <PageHelmet pageTitle='Career Growth Platform' />
                    {/* End Pagehelmet  */}

                    <Header headertransparent="header--transparent" colorblack="color--black" sublink />

                    {/* Start Breadcrumb Area */}
                    <div className="rn-page-title-area pt--120 pb--100 bg_image bg_image--23" data-black-overlay="8">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="rn-page-title text-center pt--100">
                                        <h2 className="title theme-gradient">CAREER PLATFORM</h2>
                                        <p>Positioning Individuals for Explosive Career Growth</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Breadcrump Area */}

                    {/* Start Page Wrapper */}
                    <div className="rn-service-details ptb--120 bg_color--1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="service-details-inner">
                                        <div className="inner">
                                            {/* Start Single Area */}
                                            <div className="row service-details-content pb--80 align-items-center">
                                                <div className="col-lg-6 col-12">
                                                    <div className="thumb">
                                                        <img className="mb--25 ml--20" src="/assets/images/platform-01.png" style={{width: "90%"}} alt="Career Platform - Profile"/>
                                                        <img className="ml--20" src="/assets/images/platform-02.png" style={{width: "90%"}} alt="Career Platform - Profile"/>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-12">
                                                    <div className="details mt_md--30 mt_sm--30">
                                                        <h4 className="title">Positioning is Key</h4>
                                                        <p>The Career Growth Platform is a tool to help current and aspiring technology professionals position themselves for the next great opportunity, while also serving as our centralized pool of amazing technologists.  </p>
                                                        <p>Serving as the first part of our 3-pronged strategy - <b>Positioning</b> - the platform allows users to build a profile that helps us determine how to best advise and direct their next career move.  The site lists and suggests job opportunities and career resources based on the information entered in the profile.  </p>
                                                        <h4 className="title">Personal Assessment</h4>
                                                        <p>We also provide assessments for individuals  via 1-on-1 sessions and offer customized coaching and mentorship to help fill hard and soft-skill gaps.  Furthermore we can prepare them for general career moves or specific opportunities. </p>
                                                        <p>The Career Platform is our tool for building a pool of applicants positioned to land the job!</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Single Area */}

                                            {/* Start Single Area */}
                                            <div className="row service-details-content align-items-top">
                                                <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                    <div className="details mt_md--30 mt_sm--30">
                                                        <h4 className="title">Platform Features</h4>
                                                        <ul className="liststyle">
                                                            <li>Exclusive Job Listings</li>
                                                            <li>Training videos</li>
                                                            <li>Customized training</li>
                                                            <li>1-1 Coaching Sessions</li>
                                                            <li>Available Candidate Listing (available soon)</li>
                                                            <li>Online Mentoring (available soon)</li>
                                                        </ul>
                                                        <h4 className="title">How Do I Start?</h4>
                                                        <p>It's free to sign-up.  Just click the button below or go to https://platform.100blackdevs.com and register.</p>
                                                        <p> <a className="rn-button-style--2" href="https://platform.100blackdevs.com" target="_blank" rel="noopener noreferrer"><span>Get Started</span></a></p>
                                                        <p>Once you have registered and logged-in, be sure to complete your personal profile.  That helps us match you with opportunities and to further work with you to help achieve your career goals!</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                    <div className="thumb position-relative">
                                                        <img className="mb--25" src="/assets/images/platform-03.png" style={{width: "90%"}} alt="Service Images"/>
                                                        <img  src="/assets/images/platform-04.png" style={{width: "90%"}} alt="Service Images"/>
                                                        {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                                        <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Single Area */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Page Wrapper */}
                    
                    {/* Start Back To Top */}
                    <div className="backto-top">
                        <ScrollToTop showUnder={160}>
                            <FiChevronUp />
                        </ScrollToTop>
                    </div>
                    {/* End Back To Top */}
                    
                    <Footer />
                </div>
            </React.Fragment>
        )
    }
}
export default CareerPlatform;