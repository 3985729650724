import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";
import { Link as ScrollLink  } from 'react-scroll'


class Header extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded')
        })
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }


    render(){
        const { color='default-color', sublink = false } = this.props;
        
        return(
            <header className={`header-area formobile-menu header--transparent ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <a href="/">
                                <img src="/assets/images/logo/100BlackDevs-logo.png" alt="100 Black Devs" />
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                                <li><Link to="/">Home</Link></li>
                                <li>
                                    { sublink ? (
                                        <a href="/#about">About</a>
                                    ) : (
                                        <ScrollLink href="#" to="about" spy={true} smooth={true} offset={-140} duration={500} >About</ScrollLink>
                                    )}
                                </li>
                                <li>
                                    { sublink ? (
                                        <a href="/#services">Services</a>          
                                    ) : (
                                        <ScrollLink href="#" to="services" spy={true} smooth={true} duration={500} >Services</ScrollLink>
                                    )}
                                    <ul className="submenu">
                                        <li><Link to="/talent-placement">Talent Placement</Link></li>
                                        <li><Link to="/career-platform">Career Platform</Link></li>
                                        {/* <li><Link to="/software-development">Software Development</Link></li>
                                        <li><Link to="/diversity-inclusion">DE&amp;I</Link></li> */}
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/career-platform">Platform</Link>
                                    <ul className="submenu">
                                        <li><a href="https://platform.100blackdevs.com" target="_blank" rel="noopener noreferrer">Go To Career Platform</a></li>
                                    </ul>
                                </li>
                                <li><Link to="/contact" >Contact</Link></li>
                            </ul>
                        </nav>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default Header;