import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import HomeSlider from "../elements/HomeSlider";
import About from "../elements/About";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";
import HomeServiceTwo from "../elements/HomeServiceTwo";

class Home extends Component{
    render(){
        return(
            <div className="active-dark"> 
                <Helmet pageTitle="One Hundred Black Devs" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <HomeSlider />
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div id="about" className="about-area about-position-top pb--120 bg_color--1">
                    <About />
                </div>
                {/* End About Area */}

                {/* Start Service Area  */}
                <div id="services" className="service-area ptb--80  bg_image bg_image--3">
                   <div className="container">
                        <HomeServiceTwo />
                   </div>
                </div>
                {/* End Service Area  */}

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation pb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-12">
                                <div className="section-title mt_md--5 mt_mobile--5 mb_mobile--10">
                                    <h2 className="title">Clients</h2>
                                    {/* <p>
                                        We provide services to help close the diversity gap in tech.
                                    </p> */}
                                </div>
                            </div>
                            
                            <div className="col-lg-8 col-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />
                
            </div>
        )
    }
}
export default Home;