// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import { Contact, Home, Development, TalentPlacement, CareerPlatform, DiversityInclusion } from './pages';
import error404 from "./elements/error404";
import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/software-development`} component={Development}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/talent-placement`} component={TalentPlacement}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/career-platform`} component={CareerPlatform}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/diversity-inclusion`} component={DiversityInclusion}/>
                    <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                    <Route component={error404}/>
                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();