import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} || Software and Career Development Platform </title>
                    <meta name="description" content="One Hundred Black Devs – Building software and developers at the same damn time." />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
